const photosData = [
    {
      id: 1,
      src: "ugomemo_01.jpg",
      alt: "ゾロア",
      title: "ソロア",
    },
    {
      id: 2,
      src: "ugomemo_02.jpg",
      alt: "謹賀新年2012_ドラコケンタロス",
      title: "謹賀新年2012_ドラコケンタロス",
    },
    {
        id: 3,
        src: "ugomemo_03.jpg",
        alt: "フタチマルにドレディアがキス",
        title: "フタチマルとドレディア",
        },
        {
        id: 4,
        src: "ugomemo_04.jpg",
        alt: "謹賀新年_シグ",
        title: "謹賀新年_シグ",
        },
        {
        id: 5,
        src: "ugomemo_05.jpg",
        alt: "アミティとシグ",
        title: "アミティとシグ",
        },
        {
        id: 6,
        src: "ugomemo_06.jpg",
        alt: "ダークアルル",
        title: "ダークアルル",
        },
        {
        id: 7,
        src: "ugomemo_07.jpg",
        alt: "ビビアン",
        title: "ビビアン",
        },
        {
            id: 8,
            src: "ugomemo_08.jpg",
            alt: "シグ",
            title: "シグ",
          },
          {
            id: 9,
            src: "ugomemo_09.jpg",
            alt: "ナタネ（森の洋館都市伝説パロ）",
            title: "ナタネ（森の洋館都市伝説パロ）",
          },
          {
            id: 10,
            src: "ugomemo_10.jpg",
            alt: "ジャローダ",
            title: "ジャローダ",
          },
          {
            id: 11,
            src: "ugomemo_11.jpg",
            alt: "エモンガ",
            title: "エモンガ",
          },

  ];
  
  export default photosData;