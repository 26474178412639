import React from 'react';
import './footer.css';

function footer () {
        return (
            <div className="footer_all">
                <div className="footer_title">
                    @akadra since 2016
                </div>
            </div>
        );
    }
export default footer;  